const comments = [
    {
        image: "",
        name:"Corina Dumitru",
        comment: "It tells me that my IQ is 85 😐",
        likes: "22"
    },
    {
        image: "",
        name:"Anastasia Vasilică",
        comment: "120 😎 I solved the test quickly",
        likes: "5"
    },
    {
        image: "",
        name:"Mădălina  Tomulescu",
        comment: "Interesting, I have an IQ of 105 😅",
        likes: "37"
    },
    {
        image: "",
        name:"Doina Gherban",
        comment: "🥺 Only 82, but I solved it so quickly...",
        likes: "105"
    },
    {
        image: "",
        name:"Larisa Tomulescu",
        comment: "Not bad, I got 120!",
        likes: "3"
    },
    {
        image: "",
        name:"Bogdan Stănescu",
        comment: "I have an IQ of 115 and my brother has 90.",
        likes: "8"
    },
    {
        image: "",
        name:"Cristi Ifrim",
        comment: "100 🤩 how simple it was",
        likes: "17"
    },
    {
        image: "",
        name:"Adela Pavel",
        comment: "The questions seem so simple to me. I got 120.",
        likes: "67"
    },
    {
        image: "",
        name:"Alecu Albu",
        comment: "80 😢😢😢",
        likes: "56"
    },
    {
        image: "",
        name:"Nicolae Munteanu",
        comment: "100... from the first attempt 😇",
        likes: "76"
    },
    {
        image: "",
        name:"Vlad Iancu",
        comment: "I have to give it to my friends too. I got 90.",
        likes: "54"
    },
    {
        image: "",
        name:"Alexandru Munteanu",
        comment: "I thought it was harder... apparently I have a 95 IQ",
        likes: "23"
    },
    {
        image: "",
        name:"Valeriu Dobre",
        comment: "Only 75. I think I'll try one more time 😒",
        likes: "11"
    },
    {
        image: "",
        name:"Emanuel Cristea",
        comment: "😎😎 110!! How easy!",
        likes: "98"
    },
    {
        image: "",
        name:"Sergiu Negoiță",
        comment: "I have to give it to my friend to make it. I got 100!",
        likes: "164"
    },
    {
        image: "",
        name:"David Teodorescu",
        comment: "😰 I had some emotions, but in the end it was good. 120!!",
        likes: "45"
    },
    {
        image: "",
        name:"Alfred Voinea",
        comment: "Great test. It was really interesting!",
        likes: "23"
    },
    {
        image: "",
        name:"Ovidiu Florea",
        comment: "Tomorrow I will show it to my colleagues, I don't think they will beat my score 😎",
        likes: "11"
    },
    {
        image: "",
        name:"Corina Țuțea",
        comment: "As if the one at school was harder 😅",
        likes: "24"
    },
    {
        image: "",
        name:"Elvira Tocmelea",
        comment: "Solved in 3 minutes... IQ of 110 ☺️",
        likes: "55"
    },
];

export {
    comments,
}