import { createRouter,createWebHistory } from "vue-router";
import IqTest from "../components/English/IqTest.vue";
import ResultTest from "../components/English/ResultTest.vue";

const routes = [
  {
    path: "/",
    name: "/",
    component: IqTest,
  },
  {
    path: "/result/:name/:id",
    name: "Result",
    component: ResultTest,
 
 },
 {
  path: "/ro/",
  name: "/ro/",
  component: IqTest,
},
{
  path: "/ro/result/:name/:id",
  name: "ro_Result",
  component: ResultTest,

},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});


export default router;
