const ro_comments = [
    {
        image: "",
        name:"Corina Dumitru",
        comment: "Îmi spune că IQ-ul meu este 85 😐",
        likes: "22"
    },
    {
        image: "",
        name:"Anastasia Vasilică",
        comment: "120 😎 Am rezolvat testul rapid",
        likes: "5"
    },
    {
        image: "",
        name:"Mădălina  Tomulescu",
        comment: "Interesant, am un IQ de 105 😅",
        likes: "37"
    },
    {
        image: "",
        name:"Doina Gherban",
        comment: "🥺 Doar 82, dar l-am rezolvat atât de repede...",
        likes: "105"
    },
    {
        image: "",
        name:"Larisa Tomulescu",
        comment: "Deloc rău, am obținut 120!",
        likes: "3"
    },
    {
        image: "",
        name:"Bogdan Stănescu",
        comment: "Am un IQ de 115, iar fratele meu are 90.",
        likes: "8"
    },
    {
        image: "",
        name:"Cristi Ifrim",
        comment: "100 🤩 cât de simplu a fost",
        likes: "17"
    },
    {
        image: "",
        name:"Adela Pavel",
        comment: "Îmi par atât de simple întrebările. Am obținut 120.",
        likes: "67"
    },
    {
        image: "",
        name:"Alecu Albu",
        comment: "80 😢😢😢",
        likes: "56"
    },
    {
        image: "",
        name:"Nicolae Munteanu",
        comment: "100... din prima încercare 😇",
        likes: "76"
    },
    {
        image: "",
        name:"Vlad Iancu",
        comment: "Trebuie să le dau și prietenilor mei. Am obținut 90.",
        likes: "54"
    },
    {
        image: "",
        name:"Alexandru Munteanu",
        comment: "Credeam că va fi mai greu... se pare că am un IQ de 95",
        likes: "23"
    },
    {
        image: "",
        name:"Valeriu Dobre",
        comment: "Doar 75. Cred că voi încerca încă o dată 😒",
        likes: "11"
    },
    {
        image: "",
        name:"Emanuel Cristea",
        comment: "😎😎 110!! Cât de ușor!",
        likes: "98"
    },
    {
        image: "",
        name:"Sergiu Negoiță",
        comment: "Trebuie să-i dau și prietenului meu să-l facă. Am obținut 100!",
        likes: "164"
    },
    {
        image: "",
        name:"David Teodorescu",
        comment: "😰 Am avut niște emoții, dar în final a fost bine. 120!!",
        likes: "45"
    },
    {
        image: "",
        name:"Alfred Voinea",
        comment: "Test grozav. A fost într-adevăr interesant!",
        likes: "23"
    },
    {
        image: "",
        name:"Ovidiu Florea",
        comment: "Mâine o să-l arăt colegilor mei, nu cred că vor depăși scorul meu 😎",
        likes: "11"
    },
    {
        image: "",
        name:"Corina Țuțea",
        comment: "Parcă cel de la școală a fost mai greu 😅",
        likes: "24"
    },
    {
        image: "",
        name:"Elvira Tocmelea",
        comment: "Rezolvat în 3 minute... IQ de 110 ☺️",
        likes: "55"
    },
];

export {
    ro_comments,
}