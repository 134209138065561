// IMPORTANT - The words or texts should be the same for "Choices" and "correctAnswer". It is case sensitive

const verbalComprehension = [
    {
        elements: [{
        type: "radiogroup", // Do not translate this line
        name: "Implausible",
        title: "Choose the word that is most nearly opposite in meaning to the word 'plausible':",
        isRequired: false,
        choices: [
          "Implausible", "Possible", "Probable", "Conceivable"
        ],
        correctAnswer: "Implausible"
      }],
    },
    {
      elements: [{
      type: "radiogroup",
      name: "Statue",
      title: "Which of the following words is most nearly the same in meaning to the word 'sculpture'?",
      isRequired: false,
      choices: [
        "Painting", "Drawing", "Sketch", "Statue"
      ],
      correctAnswer: "Statue"
    }],
    },
    {
      elements: [{
      type: "radiogroup",
      name: "Eating",
      title: "Choose the word that best completes the analogy: Book is to reading as fork is to:",
      isRequired: false,
      choices: [
        "Drawing", "Eating", "Writing", "Cooking"
      ],
      correctAnswer: "Eating"
    }],
    },

   {
    elements: [{
      type: "radiogroup",
      name: "Careful",
      title: "Choose the word that best completes the analogy: Bold is to timid as cautious is to:",
      isRequired: false,
      choices: [
        "Careful", "Reckless", "Nervous", "Uncertain"
      ],
      correctAnswer: "Careful"
    }],
   },
   {
    elements: [{
      type: "radiogroup",
      name: "Body",
      title: "Choose the word that best completes the analogy: Branch is to tree as vein is to:",
      isRequired: false,
      choices: [
        "Body", "Leaf", "Blood", "Heart"
      ],
      correctAnswer: "Body"
    }],
   },

   {
    elements: [{
      type: "radiogroup",
      name: "Melancholy",
      title: "Find the word that best completes the analogy: Happy is to joy as sad is to:",
      isRequired: false,
      choices: [
        "Jump", "Angry", "Fear", "Melancholy"
      ],
      correctAnswer: "Melancholy"
    }],
   },
    {
      elements: [{
      type: "radiogroup",
      name: "Deficient",
      title: "Choose the word that is most nearly opposite in meaning to the word 'abundant':",
      isRequired: false,
      choices: [
        "Copious", "Deficient", "Plentiful", "Generous"
      ],
      correctAnswer: "Deficient"
    }],
    },
    {
      elements: [{
      type: "radiogroup",
      name: "Murmur",
      title: "Choose the word that best completes the analogy: Whisper is to shout as murmur is to:",
      isRequired: false,
      choices: [
        "Murmur", "Scream", "Hum", "Sing"
      ],
      correctAnswer: "Murmur"
    }],
    },
   {
    elements: [{
      type: "radiogroup",
      name: "Transparent",
      title: "Choose the word that is most nearly the same in meaning to the word 'opaque':",
      isRequired: false,
      choices: [
        "Translucent", "Obscure", "Visible", "Transparent"
      ],
      correctAnswer: "Transparent"
    }],
   },
   {
    elements: [{
      type: "radiogroup",
      name: "Praise",
      title: "Choose the word that is most nearly opposite in meaning to the word 'criticize':",
      isRequired: false,
      choices: [
        "Ignore", "Acknowledge", "Praise", "Admonish"
      ],
      correctAnswer: "Praise"
    }],
   },
    {
      elements: [{
      type: "radiogroup",
      name: "Land",
      title: "Choose the word that best completes the analogy: River is to water as road is to:",
      isRequired: false,
      choices: [
        "Air", "Land", "Ice", "Sand"
      ],
      correctAnswer: "Land"
    }],
    },
   {
    elements: [{
      type: "radiogroup",
      name: "Narrow",
      title: "Find the word that best completes the analogy: Tall is to short as wide is to:",
      isRequired: false,
      choices: [
        "Long", "Narrow", "Deep", "Thick"
      ],
      correctAnswer: "Narrow"
    }],
   },
    {
      elements: [{
      type: "radiogroup",
      name: "Evasive",
      title: "Choose the word that is most nearly the same in meaning to the word 'elusive':",
      isRequired: false,
      choices: [
        "Evasive", "Conspicuous", "Obvious", "Attentive"
      ],
      correctAnswer: "Evasive"
    }],
    },
   {
    elements: [{
      type: "radiogroup",
      name: "Descend",
      title: "Choose the word that is most nearly opposite in meaning to the word 'ascend':",
      isRequired: false,
      choices: [
        "Descend", "Climb", "Rise", "Soar"
      ],
      correctAnswer: "Descend"
    }],
   },
    {
      elements: [{
      type: "radiogroup",
      name: "Cold",
      title: "Choose the word that best completes the analogy: Warm is to hot as cool is to:",
      isRequired: false,
      choices: [
        "Cold", "Icy", "Lukewarm", "Freezing"
      ],
      correctAnswer: "Cold"
    }],
    },
];

const logicalReasoning = [
    {
        elements: [{
        type: "radiogroup",
        name: "Nellie is gray",
        title: "All elephants are gray. Nellie is an elephant. Therefore:",
        isRequired: false,
        choices: [
          "Nellie is gray", "Nellie is not gray", "Nellie is both gray and not gray", "None of the above"
        ],
        correctAnswer: "Nellie is gray"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "Jenny's dog barks",
        title: "If all dogs bark and Jenny has a dog, then:",
        isRequired: false,
        choices: [
          "Jenny's dog barks", "Jenny's dog does not bark", "Jenny's dog may or may not bark", "None of the above"
        ],
        correctAnswer: "Jenny's dog barks"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "9",
        title: "What number comes next in the sequence: 1, 3, 5, 7, ...?",
        isRequired: false,
        choices: [
          "5", "9", "10", "11"
        ],
        correctAnswer: "9"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "Cannot be determined",
        title: "If green means go, red means stop, and yellow means slow down, what does blue mean?",
        isRequired: false,
        choices: [
          "Go", "Stop", "Slow down", "Cannot be determined"
        ],
        correctAnswer: "Cannot be determined"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "6 PM",
        title: "A train leaves from station A to station B at 4 PM, traveling at a speed of 60 mph. Another train leaves station B to station A at the same time, traveling at 80 mph. The distance between the two stations is 420 miles. At what time will the trains meet?",
        isRequired: false,
        choices: [
          "5 PM", "6 PM", "7 PM", "8 PM"
        ],
        correctAnswer: "6 PM"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "4",
        title: "How many sides does a rhombus have?",
        isRequired: false,
        choices: [
          "3", "4", "5", "6"
        ],
        correctAnswer: "4"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "Cannot be determined",
        title: "If a square has a side length of 3, what is the length of its diagonal?",
        isRequired: false,
        choices: [
          "3", "6", "9", "Cannot be determined"
        ],
        correctAnswer: "Cannot be determined"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "32",
        title: "What number comes next in the sequence: 2, 4, 8, 16, ...?",
        isRequired: false,
        choices: [
          "18", "24", "32", "64"
        ],
        correctAnswer: "32"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "90 pounds",
        title: "John weighs twice as much as Mary. Mary weighs half as much as Tom. If Tom weighs 180 pounds, how much does John weigh?",
        isRequired: false,
        choices: [
          "60 pounds", "90 pounds", "180 pounds", "360 pounds"
        ],
        correctAnswer: "90 pounds"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "False",
        title: "If John always tells the truth on Mondays and lies on all other days, and he says 'Today is not Monday' today, is it Monday?",
        isRequired: false,
        choices: [
          "True", "False", "Cannot be determined", "None of the above"
        ],
        correctAnswer: "False"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "True",
        title: "If some cats are black and all black animals are nocturnal, are some cats nocturnal?",
        isRequired: false,
        choices: [
          "True", "False", "Cannot be determined", "None of the above"
        ],
        correctAnswer: "True"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A man has 3 daughters.",
        title: "A man has 3 daughters. Each daughter has twice as many brothers as sisters. How many children does the man have in total?",
        isRequired: false,
        choices: [
          "6", "7", "8", "9"
        ],
        correctAnswer: "8"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "If it takes 5 machines 5 minutes to make 5 widgets, how long would it take 100 machines to make 100 widgets?",
        title: "If it takes 5 machines 5 minutes to make 5 widgets, how long would it take 100 machines to make 100 widgets?",
        isRequired: false,
        choices: [
          "1 minute", "5 minutes", "20 minutes", "100 minutes"
        ],
        correctAnswer: "5 minutes"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "At a party, everyone shook hands with everybody else. There were 66 handshakes. How many people were at the party?",
        title: "At a party, everyone shook hands with everybody else. There were 66 handshakes. How many people were at the party?",
        isRequired: false,
        choices: [
          "11", "12", "13", "14"
        ],
        correctAnswer: "12"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A man gave half of his money to his wife and half of the remainder to his son. He had $50 left. How much money did he have initially?",
        title: "A man gave half of his money to his wife and half of the remainder to his son. He had $50 left. How much money did he have initially?",
        isRequired: false,
        choices: [
          "$100", "$150", "$200", "$250"
        ],
        correctAnswer: "$200"
      }],
    },
];


const numericalAptitude = [
   {
        elements: [{
        type: "radiogroup",
        name: "What is the next number in the sequence: 1, 3, 6, 10, ...?",
        title: "What is the next number in the sequence: 1, 3, 6, 10, ...?",
        isRequired: false,
        choices: [
          "11", "13", "15", "17"
        ],
        correctAnswer: "13"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "The sum of two numbers is 30, and their difference is 10. What is the larger of the two numbers?",
        title: "The sum of two numbers is 30, and their difference is 10. What is the larger of the two numbers?",
        isRequired: false,
        choices: [
          "10", "20", "25", "30"
        ],
        correctAnswer: "20"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "The product of three consecutive even numbers is 11,040. What is the smallest of these numbers?",
        title: "The product of three consecutive even numbers is 11,040. What is the smallest of these numbers?",
        isRequired: false,
        choices: [
          "40", "44", "46", "48"
        ],
        correctAnswer: "44"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "If 5x = 15, what is the value of x?",
        title: "If 5x = 15, what is the value of x?",
        isRequired: false,
        choices: [
          "0", "1", "3", "5"
        ],
        correctAnswer: "3"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "What is the least common multiple of 2, 3, and 4?",
        title: "What is the least common multiple of 2, 3, and 4?",
        isRequired: false,
        choices: [
          "2", "4", "6", "12"
        ],
        correctAnswer: "12"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "What fraction is equivalent to 25%?",
        title: "What fraction is equivalent to 25%?",
        isRequired: false,
        choices: [
          "1/4", "1/3", "1/2", "3/4"
        ],
        correctAnswer: "1/4"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A store offers a 25% discount on an item. The sale price is $15. How much was the item's original price?",
        title: "A store offers a 25% discount on an item. The sale price is $15. How much was the item's original price?",
        isRequired: false,
        choices: [
          "$10", "$20", "$25", "$30"
        ],
        correctAnswer: "$20"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "If a store offers a 30% discount on an item, and the sale price is $21, what was the original price?",
        title: "If a store offers a 30% discount on an item, and the sale price is $21, what was the original price?",
        isRequired: false,
        choices: [
          "$15", "$28", "$30", "$35"
        ],
        correctAnswer: "$35"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A train travels at a constant speed of 60 miles per hour. How long will it take to travel 180 miles?",
        title: "A train travels at a constant speed of 60 miles per hour. How long will it take to travel 180 miles?",
        isRequired: false,
        choices: [
          "1 hour", "2 hours", "3 hours", "4 hours"
        ],
        correctAnswer: "3 hours"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A rectangular room measures 12 feet by 18 feet. What is the area of the room?",
        title: "A rectangular room measures 12 feet by 18 feet. What is the area of the room?",
        isRequired: false,
        choices: [
          "108 square feet", "144 square feet", "216 square feet", "324 square feet"
        ],
        correctAnswer: "216 square feet"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "In a triangle, the sum of two angles is 120 degrees, and the third angle is 60 degrees. How many degrees are in the triangle?",
        title: "In a triangle, the sum of two angles is 120 degrees, and the third angle is 60 degrees. How many degrees are in the triangle?",
        isRequired: false,
        choices: [
          "180", "240", "300", "360"
        ],
        correctAnswer: "180"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "Solve for x: 4x - 10 = 30",
        title: "Solve for x: 4x - 10 = 30",
        isRequired: false,
        choices: [
          "5", "10", "20", "25"
        ],
        correctAnswer: "20"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A car travels 150 miles in 2.5 hours. What is the average speed of the car?",
        title: "A car travels 150 miles in 2.5 hours. What is the average speed of the car?",
        isRequired: false,
        choices: [
          "40 mph", "60 mph", "75 mph", "80 mph"
        ],
        correctAnswer: "60 mph"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A triangle has angles of 30, 60, and 90 degrees. If the shortest side is 10 units long, what is the length of the longest side?",
        title: "A triangle has angles of 30, 60, and 90 degrees. If the shortest side is 10 units long, what is the length of the longest side?",
        isRequired: false,
        choices: [
          "10", "15", "20", "Cannot be determined"
        ],
        correctAnswer: "Cannot be determined"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "If you have 5 apples and 3 oranges, how many fruits do you have in total?",
        title: "If you have 5 apples and 3 oranges, how many fruits do you have in total?",
        isRequired: false,
        choices: [
          "6", "8", "10", "12"
        ],
        correctAnswer: "8"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A factory produces 1,000 items in 8 hours. How many items can it produce in 5 hours?",
        title: "A factory produces 1,000 items in 8 hours. How many items can it produce in 5 hours?",
        isRequired: false,
        choices: [
          "500", "625", "750", "1,000"
        ],
        correctAnswer: "625"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "The product of two numbers is 120. If one of the numbers is 10, what is the other number?",
        title: "The product of two numbers is 120. If one of the numbers is 10, what is the other number?",
        isRequired: false,
        choices: [
          "2", "5", "12", "20"
        ],
        correctAnswer: "12"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A rectangular garden is 20 meters long and 10 meters wide. What is the perimeter of the garden?",
        title: "A rectangular garden is 20 meters long and 10 meters wide. What is the perimeter of the garden?",
        isRequired: false,
        choices: [
          "30 meters", "60 meters", "100 meters", "200 meters"
        ],
        correctAnswer: "60 meters"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "If a rectangular room is 15 feet long and 10 feet wide, what is the length of the diagonal?",
        title: "If a rectangular room is 15 feet long and 10 feet wide, what is the length of the diagonal?",
        isRequired: false,
        choices: [
          "18 feet", "20 feet", "25 feet", "30 feet"
        ],
        correctAnswer: "18 feet"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A quarter is how much more than a dime?",
        title: "A quarter is how much more than a dime?",
        isRequired: false,
        choices: [
          "5 cents", "10 cents", "15 cents", "20 cents"
        ],
        correctAnswer: "15 cents"
      }],
    },
];



const spatialIntelligence = [
    {
        elements: [{
        type: "radiogroup",
        name: "Which of the following shapes has the most sides?",
        title: "Which of the following shapes has the most sides?",
        isRequired: false,
        choices: [
          "Triangle", "Square", "Pentagon", "Hexagon"
        ],
        correctAnswer: "Hexagon"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "How many vertices does a cube have?",
        title: "How many vertices does a cube have?",
        isRequired: false,
        choices: [
          "4", "6", "8", "12"
        ],
        correctAnswer: "8"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "What shape is formed when a rectangle is folded in half along its diagonal?",
        title: "What shape is formed when a rectangle is folded in half along its diagonal?",
        isRequired: false,
        choices: [
          "Triangle", "Square", "Pentagon", "Hexagon"
        ],
        correctAnswer: "Triangle"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "Which of the following shapes has the most corners?",
        title: "Which of the following shapes has the most corners?",
        isRequired: false,
        choices: [
          "Circle", "Triangle", "Square", "Pentagon"
        ],
        correctAnswer: "Pentagon"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "How many faces does a pentagonal pyramid have?",
        title: "How many faces does a pentagonal pyramid have?",
        isRequired: false,
        choices: [
          "4", "5", "6", "7"
        ],
        correctAnswer: "6"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "How many vertices does a tetrahedron have?",
        title: "How many vertices does a tetrahedron have?",
        isRequired: false,
        choices: [
          "3", "4", "6", "8"
        ],
        correctAnswer: "4"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "How many edges does a cube have?",
        title: "How many edges does a cube have?",
        isRequired: false,
        choices: [
          "6", "8", "10", "12"
        ],
        correctAnswer: "12"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "How many vertices does an octahedron have?",
        title: "How many vertices does an octahedron have?",
        isRequired: false,
        choices: [
          "4", "6", "8", "12"
        ],
        correctAnswer: "6"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A circle with a radius of 10 units is inscribed in a square. What is the area of the square?",
        title: "A circle with a radius of 10 units is inscribed in a square. What is the area of the square?",
        isRequired: false,
        choices: [
          "100 square units", "200 square units", "300 square units", "400 square units"
        ],
        correctAnswer: "200 square units"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "How many degrees are in the sum of the angles of a quadrilateral?",
        title: "How many degrees are in the sum of the angles of a quadrilateral?",
        isRequired: false,
        choices: [
          "180", "270", "360", "450"
        ],
        correctAnswer: "360"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "Which of the following shapes has the most faces?",
        title: "Which of the following shapes has the most faces?",
        isRequired: false,
        choices: [
          "Cone", "Cylinder", "Sphere", "Pyramid"
        ],
        correctAnswer: "Cylinder"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "Which of the following shapes has the least sides?",
        title: "Which of the following shapes has the least sides?",
        isRequired: false,
        choices: [
          "Triangle", "Square", "Pentagon", "Hexagon"
        ],
        correctAnswer: "Triangle"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "A circle with a radius of 6 units is inscribed in a regular hexagon. What is the perimeter of the hexagon?",
        title: "A circle with a radius of 6 units is inscribed in a regular hexagon. What is the perimeter of the hexagon?",
        isRequired: false,
        choices: [
          "24 units", "36 units", "48 units", "72 units"
        ],
        correctAnswer: "48 units"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "What is the area of a triangle with a base of 5 units and a height of 10 units?",
        title: "What is the area of a triangle with a base of 5 units and a height of 10 units?",
        isRequired: false,
        choices: [
          "5 square units", "10 square units", "25 square units", "50 square units"
        ],
        correctAnswer: "50 square units"
      }],
    },
    {
        elements: [{
        type: "radiogroup",
        name: "How many degrees are in the sum of the angles of a triangle?",
        title: "How many degrees are in the sum of the angles of a triangle?",
        isRequired: false,
        choices: [
          "90", "180", "270", "360"
        ],
        correctAnswer: "180"
      }],
    },
];


export {
    verbalComprehension,
    logicalReasoning,
    numericalAptitude,
    spatialIntelligence,
}