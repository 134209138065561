import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import VueSocialSharing from 'vue-social-sharing';
import Notifications from '@kyvg/vue3-notification';
import Bootstrap from 'bootstrap';
import QrcodeVue from 'qrcode.vue'

// import VueMeta from 'vue-meta';

const appInstance = createApp(App);
appInstance.use(Bootstrap);
appInstance.use(Notifications);
appInstance.use(VueSocialSharing);
appInstance.use(QrcodeVue);
// appInstance.use(VueMeta);
appInstance.use(router);
appInstance.mount('#app')
