<template>
  <router-view />
</template>

<script>
// import IqTest from './components/IqTest.vue'
// import ResultTest from './components/ResultTest.vue'

export default {
  name: 'App',
  components: {
    // IqTest,
    // ResultTest,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
