// IMPORTANT - The words or texts should be the same for "Choices" and "correctAnswer". It is case sensitive

const ro_verbalComprehension = [
    {
        elements: [{
      type: "radiogroup", // Do not translate this line
      name: "Neplauzibil",
      title: "Alegeți cuvântul care este cel mai aproape opus în sensul cuvântului „plauzibil”:",
      isRequired: false,
      choices: ["Neplauzibil", "Posibil", "Probabil", "De conceput"],
      correctAnswer: "Neplauzibil"
    }],
  },
    {
    elements: [{
      type: "radiogroup",
      name: "Statuie",
      title: "Care dintre următoarele cuvinte este cel mai aproape același ca înțeles cu cuvântul „sculptură”?",
      isRequired: false,
      choices: ["Pictura", "Desen", "Schiță", "Statuie"],
      correctAnswer: "Statuie"
    }],
  },
    {
    elements: [{
      type: "radiogroup",
      name: "Mâncare",
      title: "Alegeți cuvântul care completează cel mai bine analogia: Cartea este pentru lectură așa cum furculița este pentru:",
      isRequired: false,
      choices: [
        "Desen", "Mâncare", "Scriere", "Gătit"
      ],
      correctAnswer: "Mâncare"
    }],
  },
  
  {
    elements: [{
      type: "radiogroup",
      name: "Atent",
      title: "Alegeți cuvântul care completează cel mai bine analogia: Îndrăzneț este pentru timid așa cum precaut este pentru:",
      isRequired: false,
      choices: [
        "Atent", "Nechibzuit", "Agitat", "Incert"
      ],
      correctAnswer: "Atent"
    }],
  },
  {
    elements: [{
      type: "radiogroup",
      name: "Corp",
      title: "Alegeți cuvântul care completează cel mai bine analogia: Ramura este pentru arbore, așa cum vena este pentru:",
      isRequired: false,
      choices: [
        "Corp", "Frunză", "Sânge", "Inimă"
      ],
      correctAnswer: "Corp"
    }],
  },
  
  {
    elements: [{
      type: "radiogroup",
      name: "Melancolie",
      title: "Găsiți cuvântul care completează cel mai bine analogia: Fericit este pentru bucurie, așa cum trist este pentru:",
      isRequired: false,
      choices: [
        "Săritură", "Furios", "Frică", "Melancolie"
      ],
      correctAnswer: "Melancolie"
    }],
  },
    {
    elements: [{
      type: "radiogroup",
      name: "Deficient",
      title: "Alegeți cuvântul care este cel mai aproape opus în sensul cuvântului „abundent”:",
      isRequired: false,
      choices: [
        "Copios", "Deficient", "Abundent", "Generos"
      ],
      correctAnswer: "Deficient"
    }],
  },
    {
    elements: [{
      type: "radiogroup",
      name: "Murmur",
      title: "Alegeți cuvântul care completează cel mai bine analogia: Șoapta este pentru strigare așa cum murmurul este pentru:",
      isRequired: false,
      choices: [
        "Murmur", "Ţipăt", "Zumzet", "Cântare" 
      ],
      correctAnswer: "Murmur"
    }],
  },
  {
    elements: [{
      type: "radiogroup",
      name: "Transparent",
      title: "Alegeți cuvântul care este cel mai aproape același ca înțeles cu cuvântul „opac”:",
      isRequired: false,
      choices: [
        "Translucid", "Obscur", "Vizibil", "Transparent"
      ],
      correctAnswer: "Transparent"
    }],
  },
  {
    elements: [{
      type: "radiogroup",
      name: "Laudă",
      title: "Alegeți cuvântul care este cel mai aproape opus în sensul cuvântului „critica”:",
      isRequired: false,
      choices: [
        "Ignora", "Recunoaștere", "Laudă", "Admonestează"
      ],
      correctAnswer: "Laudă"
    }],
  },
    {
    elements: [{
      type: "radiogroup",
      name: "Pământ",
      title: "Alegeți cuvântul care completează cel mai bine analogia: Râul este pentru apă, așa cum drumul este pentru:",
      isRequired: false,
      choices: [
        "Aer", "Pământ", "Gheaţă", "Nisip"
      ],
      correctAnswer: "Pământ"
    }],
  },
  {
    elements: [{
      type: "radiogroup",
      name: "Îngust",
      title: "Găsiți cuvântul care completează cel mai bine analogia: Înalt este pentru scurt, așa cum lat este pentru:",
      isRequired: false,
      choices: [
        "Lung", "Îngust", "Adânc", "Gros"
      ],
      correctAnswer: "Îngust"
    }],
  },
    {
    elements: [{
      type: "radiogroup",
      name: "Evaziv",
      title: "Alegeți cuvântul care este cel mai aproape același ca înțeles cu cuvântul „eluziv”:",
      isRequired: false,
      choices: [
        "Evaziv", "Remarcabil", "Evident", "Atent"
      ],
      correctAnswer: "Evaziv"
    }],
  },
  {
    elements: [{
      type: "radiogroup",
      name: "Coborâre",
      title: "Alegeți cuvântul care este cel mai aproape opus în sensul cuvântului „urcare”:",
      isRequired: false,
      choices: [
        "Coborâre", "Urcare", "Înălţare", "Avântare"
      ],
      correctAnswer: "Coborâre"
    }],
  },
    {
    elements: [{
      type: "radiogroup",
      name: "Frig",
      title: "Alegeți cuvântul care completează cel mai bine analogia: Cald este pentru fierbinte, precum răcoros este pentru:",
      isRequired: false,
      choices: [
        "Frig", "Îngheț", "Călduț", "Congelare"
      ],
      correctAnswer: "Frig"
    }],
  },
  ];
  
  const ro_logicalReasoning = [
    {
        elements: [{
      type: "radiogroup",
      name: "Nellie este gri",
      title: "Toți elefanții sunt gri. Nellie este un elefant. Prin urmare:",
      isRequired: false,
      choices: [
        "Nellie este gri", "Nellie nu este gri", "Nellie este și cenușie și nu gri", "Nici una dintre cele de mai sus"
      ],
      correctAnswer: "Nellie este gri"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câinele lui Jenny latră",
      title: "Dacă toți câinii latră și Jenny are un câine, atunci:",
      isRequired: false,
      choices: [
        "Câinele lui Jenny latră", "Câinele lui Jenny nu latră", "Câinele lui Jenny poate latră sau nu", "Nici una dintre cele de mai sus"
      ],
      correctAnswer: "Câinele lui Jenny latră"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "9",
      title: "Ce număr urmează în secvența: 1, 3, 5, 7, ...?",
      isRequired: false,
      choices: [
        "5", "9", "10", "11"
      ],
      correctAnswer: "9"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Nu poate fi determinat",
      title: "Dacă verde înseamnă mergi, roșu înseamnă oprire, iar galben înseamnă încetinire, ce înseamnă albastru?",
      isRequired: false,
      choices: ["Mergi", "Stop", "Încetinire", "Nu poate fi determinat"],
      correctAnswer: "Nu poate fi determinat"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "6 PM",
      title: "Un tren pleacă de la stația A la stația B la ora 16:00, cu o viteză de 60 mph. Un alt tren pleacă din stația B către stația A în același timp, călătorind cu 80 mph. Distanța dintre cele două stații este de 420 de mile. La ce oră se vor întâlni trenurile?",
      isRequired: false,
      choices: [
        "5 PM", "6 PM", "7 PM", "8 PM"
      ],
      correctAnswer: "6 PM"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "4",
      title: "Câte laturi are un romb?",
      isRequired: false,
      choices: [
        "3", "4", "5", "6"
      ],
      correctAnswer: "4"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Nu poate fi determinat",
      title: "Dacă un pătrat are lungimea laturii de 3, care este lungimea diagonalei sale?",
      isRequired: false,
      choices: [
        "3", "6", "9", "Nu poate fi determinat"
      ],
      correctAnswer: "Nu poate fi determinat"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "32",
      title: "Ce număr urmează în secvența: 2, 4, 8, 16, ...?",
      isRequired: false,
      choices: [
        "18", "24", "32", "64"
      ],
      correctAnswer: "32"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "45 kg",
      title: "Ioan cântărește de două ori mai mult decât Mary. Mary cântărește jumătate mai mult decât Tom. Dacă Tom cântărește 90 kg, cât cântărește John?",
      isRequired: false,
      choices: [
        "30 kg", "45 kg", "90 kg", "180 kg"
      ],
      correctAnswer: "45 kg"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Fals",
      title: "Dacă Ion spune întotdeauna adevărul în zilele de luni și minte în toate celelalte zile, iar astăzi spune „Azi nu este luni”, este luni?",
      isRequired: false,
      choices: ["Adevărat", "Fals", "Nu poate fi determinat", "Nici una dintre cele de mai sus"],
      correctAnswer: "Fals"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Adevărat",
      title: "Dacă unele pisici sunt negre și toate animalele negre sunt nocturne, unele pisici sunt nocturne?",
      isRequired: false,
      choices: ["Adevărat", "Fals", "Nu poate fi determinat", "Nici una dintre cele de mai sus"],
      correctAnswer: "Adevărat"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un bărbat are 3 fete.",
      title: "Un bărbat are 3 fete. Fiecare fiică are de două ori mai mulți frați decât surori. Câți copii are bărbatul în total?",
      isRequired: false,
      choices: [
        "6", "7", "8", "9"
      ],
      correctAnswer: "8"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Dacă este nevoie de 5 mașini și 5 minute pentru a face 5 widget-uri, cât timp ar dura 100 de mașini pentru a face 100 de widget-uri?",
      title: "Dacă este nevoie de 5 mașini și 5 minute pentru a face 5 widget-uri, cât timp ar dura 100 de mașini pentru a face 100 de widget-uri?",
      isRequired: false,
      choices: [
        "1 minut", "5 minute", "20 minute", "100 minute"
      ],
      correctAnswer: "5 minute"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "La o petrecere, toată lumea a dat mâna cu toți ceilalți. Au fost 66 de strângeri de mână. Câți oameni au fost la petrecere?",
      title: "La o petrecere, toată lumea a dat mâna cu toți ceilalți. Au fost 66 de strângeri de mână. Câți oameni au fost la petrecere?",
      isRequired: false,
      choices: [
        "11", "12", "13", "14"
      ],
      correctAnswer: "12"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un bărbat i-a dat jumătate din bani soției sale și jumătate din restul fiului său. Mai aveau 50 de dolari. Câți bani avea inițial?",
      title: "Un bărbat i-a dat jumătate din bani soției sale și jumătate din restul fiului său. Mai aveau 50 de dolari. Câți bani avea inițial?",
      isRequired: false,
      choices: [
        "$100", "$150", "$200", "$250"
      ],
      correctAnswer: "$200"
    }],
  },
  ];
  
  
  const ro_numericalAptitude = [
  {
        elements: [{
      type: "radiogroup",
      name: "Care este următorul număr din secvența: 1, 3, 6, 10, ...?",
      title: "Care este următorul număr din secvența: 1, 3, 6, 10, ...?",
      isRequired: false,
      choices: [
        "11", "13", "15", "17"
      ],
      correctAnswer: "13"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Suma a două numere este 30, iar diferența lor este 10. Care este cel mai mare dintre cele două numere?",
      title: "Suma a două numere este 30, iar diferența lor este 10. Care este cel mai mare dintre cele două numere?",
      isRequired: false,
      choices: [
        "10", "20", "25", "30"
      ],
      correctAnswer: "20"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
       name: "Produsul a trei numere pare consecutive este 11.040. Care este cel mai mic dintre aceste numere?",
      title: "Produsul a trei numere pare consecutive este 11.040. Care este cel mai mic dintre aceste numere?",
      isRequired: false,
      choices: [
        "40", "44", "46", "48"
      ],
      correctAnswer: "44"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Dacă 5x = 15, care este valoarea lui x?",
      title: "Dacă 5x = 15, care este valoarea lui x?",
      isRequired: false,
      choices: [
        "0", "1", "3", "5"
      ],
      correctAnswer: "3"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Care este cel mai mic multiplu comun al lui 2, 3 și 4?",
      title: "Care este cel mai mic multiplu comun al lui 2, 3 și 4?",
      isRequired: false,
      choices: [
        "2", "4", "6", "12"
      ],
      correctAnswer: "12"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Ce fracție este echivalentă cu 25%?",
      title: "Ce fracție este echivalentă cu 25%?",
      isRequired: false,
      choices: [
        "1/4", "1/3", "1/2", "3/4"
      ],
      correctAnswer: "1/4"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un magazin oferă o reducere de 25% la un articol. Prețul de vânzare este de 15 USD. Cât a fost prețul inițial al articolului?",
      title: "Un magazin oferă o reducere de 25% la un articol. Prețul de vânzare este de 15 USD. Cât a fost prețul inițial al articolului?",
      isRequired: false,
      choices: [
        "$10", "$20", "$25", "$30"
      ],
      correctAnswer: "$20"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Dacă un magazin oferă o reducere de 30% la un articol, iar prețul de vânzare este de 21 USD, care a fost prețul inițial?",
      title: "Dacă un magazin oferă o reducere de 30% la un articol, iar prețul de vânzare este de 21 USD, care a fost prețul inițial?",
      isRequired: false,
      choices: [
        "$15", "$28", "$30", "$35"
      ],
      correctAnswer: "$35"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un tren se deplasează cu o viteză constantă de 60 km/h. Cât timp va dura să parcurgă 180 km?",
      title: "Un tren se deplasează cu o viteză constantă de 60 km/h. Cât timp va dura să parcurgă 180 km?",
      isRequired: false,
      choices: ["1 ora", "2 ore", "3 ore", "4 ore"],
      correctAnswer: "3 ore"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "O cameră dreptunghiulară măsoară 12 metri pe 18 metri. Care este zona camerei?",
      title: "O cameră dreptunghiulară măsoară 12 metri pe 18 metri. Care este zona camerei?",
      isRequired: false,
      choices: ["108 metri pătrați", "144 metri pătrați", "216 metri pătrați", "324 metri pătrați"],
      correctAnswer: "216 metri pătrați"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Într-un triunghi, suma a două unghiuri este de 120 de grade, iar al treilea unghi este de 60 de grade. Câte grade sunt în triunghi?",
      title: "Într-un triunghi, suma a două unghiuri este de 120 de grade, iar al treilea unghi este de 60 de grade. Câte grade sunt în triunghi?",
      isRequired: false,
      choices: [
        "180", "240", "300", "360"
      ],
      correctAnswer: "180"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Rezolvați pentru x: 4x - 10 = 30",
      title: "Rezolvați pentru x: 4x - 10 = 30",
      isRequired: false,
      choices: [
        "5", "10", "20", "25"
      ],
      correctAnswer: "20"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "O mașină parcurge 150 de km în 2,5 ore. Care este viteza medie a mașinii?",
      title: "O mașină parcurge 150 de km în 2,5 ore. Care este viteza medie a mașinii?",
      isRequired: false,
      choices: [
        "40 km/h", "60 km/h", "75 km/h", "80 km/h"
      ],
      correctAnswer: "60 km/h"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un triunghi are unghiuri de 30, 60 și 90 de grade. Dacă cea mai scurtă latură are 10 unități lungime, care este lungimea celei mai lungi laturi?",
      title: "Un triunghi are unghiuri de 30, 60 și 90 de grade. Dacă cea mai scurtă latură are 10 unități lungime, care este lungimea celei mai lungi laturi?",
      isRequired: false,
      choices: [
        "10", "15", "20", "Nu poate fi determinat"
      ],
      correctAnswer: "Nu poate fi determinat"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Daca ai 5 mere si 3 portocale, cate fructe ai in total?",
      title: "Daca ai 5 mere si 3 portocale, cate fructe ai in total?",
      isRequired: false,
      choices: [
        "6", "8", "10", "12"
      ],
      correctAnswer: "8"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "O fabrică produce 1.000 de articole în 8 ore. Câte articole poate produce în 5 ore?",
      title: "O fabrică produce 1.000 de articole în 8 ore. Câte articole poate produce în 5 ore?",
      isRequired: false,
      choices: [
        "500", "625", "750", "1,000"
      ],
      correctAnswer: "625"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Produsul a două numere este 120. Dacă unul dintre numere este 10, care este celălalt număr?",
      title: "Produsul a două numere este 120. Dacă unul dintre numere este 10, care este celălalt număr?",
      isRequired: false,
      choices: [
        "2", "5", "12", "20"
      ],
      correctAnswer: "12"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "O grădină dreptunghiulară are 20 de metri lungime și 10 metri lățime. Care este perimetrul grădinii?",
      title: "O grădină dreptunghiulară are 20 de metri lungime și 10 metri lățime. Care este perimetrul grădinii?",
      isRequired: false,
      choices: [
        "30 metri", "60 metri", "100 metri", "200 metri"
      ],
      correctAnswer: "60 metri"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Dacă o cameră dreptunghiulară are 15 metri lungime și 10 metri lățime, care este lungimea diagonalei?",
      title: "Dacă o cameră dreptunghiulară are 15 metri lungime și 10 metri lățime, care este lungimea diagonalei?",
      isRequired: false,
      choices: [
        "18 metri", "20 metri", "25 metri", "30 metri"
      ],
      correctAnswer: "18 metri"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un sfert înseamnă cu cât mai mult decât un ban?",
      title: "Un sfert înseamnă cu cât mai mult decât un ban?",
      isRequired: false,
      choices: [
        "5 bani", "10 bani", "15 bani", "20 bani"
      ],
      correctAnswer: "15 bani"
    }],
  },
  ];
  
  
  
  const ro_spatialIntelligence = [
    {
        elements: [{
      type: "radiogroup",
      name: "Care dintre următoarele forme are cele mai multe laturi?",
      title: "Care dintre următoarele forme are cele mai multe laturi?",
      isRequired: false,
      choices: ["Triunghi", "Pătrat", "Pentagon", "Hexagon"],
      correctAnswer: "Hexagon"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câte vârfuri are un cub?",
      title: "Câte vârfuri are un cub?",
      isRequired: false,
      choices: [
        "4", "6", "8", "12"
      ],
      correctAnswer: "8"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Ce formă se formează când un dreptunghi este pliat în jumătate de-a lungul diagonalei sale?",
      title: "Ce formă se formează când un dreptunghi este pliat în jumătate de-a lungul diagonalei sale?",
      isRequired: false,
      choices: ["Triunghi", "Pătrat", "Pentagon", "Hexagon"],
      correctAnswer: "Triunghi"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Care dintre următoarele forme are cele mai multe colțuri?",
      title: "Care dintre următoarele forme are cele mai multe colțuri?",
      isRequired: false,
      choices: ["Cerc", "Triunghi", "Pătrat", "Pentagon"],
      correctAnswer: "Pentagon"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câte fețe are o piramidă pentagonală?",
      title: "Câte fețe are o piramidă pentagonală?",
      isRequired: false,
      choices: [
        "4", "5", "6", "7"
      ],
      correctAnswer: "6"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câte vârfuri are un tetraedru?",
      title: "Câte vârfuri are un tetraedru?",
      isRequired: false,
      choices: [
        "3", "4", "6", "8"
      ],
      correctAnswer: "4"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câte muchii are un cub?",
      title: "Câte muchii are un cub?",
      isRequired: false,
      choices: [
        "6", "8", "10", "12"
      ],
      correctAnswer: "12"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câte vârfuri are un octaedru?",
      title: "Câte vârfuri are un octaedru?",
      isRequired: false,
      choices: [
        "4", "6", "8", "12"
      ],
      correctAnswer: "6"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un cerc cu raza de 10 unități este înscris într-un pătrat. Care este aria pătratului?",
      title: "Un cerc cu raza de 10 unități este înscris într-un pătrat. Care este aria pătratului?",
      isRequired: false,
      choices: ["100 unități pătrate", "200 unități pătrate", "300 unități pătrate", "400 unități pătrate"],
      correctAnswer: "200 unități pătrate"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câte grade sunt în suma unghiurilor unui patrulater?",
      title: "Câte grade sunt în suma unghiurilor unui patrulater?",
      isRequired: false,
      choices: [
        "180", "270", "360", "450"
      ],
      correctAnswer: "360"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Care dintre următoarele forme are cele mai multe fețe?",
      title: "Care dintre următoarele forme are cele mai multe fețe?",
      isRequired: false,
      choices: ["Con", "Cilindru", "Sferă", "Piramidă"],
      correctAnswer: "Cilindru"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Care dintre următoarele forme are cele mai mici laturi?",
      title: "Care dintre următoarele forme are cele mai mici laturi?",
      isRequired: false,
      choices: ["Triunghi", "Pătrat", "Pentagon", "Hexagon"],
      correctAnswer: "Triunghi"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Un cerc cu raza de 6 unități este înscris într-un hexagon regulat. Care este perimetrul hexagonului?",
      title: "Un cerc cu raza de 6 unități este înscris într-un hexagon regulat. Care este perimetrul hexagonului?",
      isRequired: false,
      choices: ["24 unități", "36 unități", "48 unități", "72 unități"],
      correctAnswer: "48 unități"
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Care este aria unui triunghi cu baza de 5 unități și înălțimea de 10 unități?",
      title: "Care este aria unui triunghi cu baza de 5 unități și înălțimea de 10 unități?",
      isRequired: false,
      choices: ["5 unități pătrate", "10 unități pătrate", "25 unități pătrate", "50 unități pătrate"],
      correctAnswer: "50 unități pătrate",
    }],
  },
    {
        elements: [{
      type: "radiogroup",
      name: "Câte grade sunt în suma unghiurilor unui triunghi?",
      title: "Câte grade sunt în suma unghiurilor unui triunghi?",
      isRequired: false,
      choices: [
        "90", "180", "270", "360"
      ],
      correctAnswer: "180"
    }],
  },
  ];
  
  
  export {
    ro_verbalComprehension,
    ro_logicalReasoning,
    ro_numericalAptitude,
    ro_spatialIntelligence,
  }